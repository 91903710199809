<template>
  <div class="scroller">
    <!-- name -->
    <section>
      <Dialog
        v-model:visible="emailModal"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '50vw' }"
      >
        <template #header>
          <h4>I'm excited to chat!</h4>
        </template>
        <Socials />
      </Dialog>

      <div class="container">
        <div class="title">
          <h1>Alejandro</h1>
          <p>I'm addicted to learning</p>
          <button @click="openEmailModal" class="cta-btn">
            <i class="pi pi-at" style="font-size: 1.5rem"></i> Contact me
          </button>
        </div>
      </div>
    </section>
    <!-- intro -->
    <section>
      <div class="container">
        <div class="content">
          <h1>A bit about me</h1>
          <p class="description">
            I could tell you a lot about me, but if there is one thing I want
            you to remember is that I am extremely curious.
            <br /><br />
            My experience has led me to understand the true value of people and
            the power of team building, culture and diversity in all aspects.
          </p>
        </div>
      </div>
    </section>
    <!-- skillz -->
    <section>
      <div class="container">
        <div class="content">
          <h1>Things I know and use quite often</h1>
          <Images :data="skills" />
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <div class="content">
          <h1>Things I'm getting into</h1>
          <Images :data="skillsToBe" />
        </div>
      </div>
    </section>
    <!-- work -->
    <section>
      <div class="container">
        <div class="content">
          <h1>Places I've worked at</h1>
        </div>
        <Card :jobs="jobs" :mobile="mobile" />
      </div>
    </section>
    <!-- contact -->
    <section>
      <div class="container">
        <div class="content">
          <h1>Let's connect!</h1>
          <Socials :type="'page'" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card.vue";
import Images from "@/components/ImageGrid.vue";
import Socials from "@/components/SocialsComponent.vue";

export default {
  components: { Card, Images, Socials },
  name: "HomeView",
  data() {
    return {
      types: ["job", "skill"],
      emailModal: null,
      email: null,
      mobile: null,
      jobs: [
        {
          company: "Gravitate",
          title: "Technical Consultant, Business Analyst",
          description:
            "We deliver top-tier supply optimization software. I work on implementing the software and managing client relationships",
          img: "gravitate.png",
          url: "gravitate.energy",
        },
        {
          company: "ModThink Marketing",
          title: "Jr. Business Analyst",
          description:
            "I worked with the team on higher education Marketing plans. My role was to empower clients to make data-driven decisions.",
          img: "modthink.png",
          url: "modthink.com",
        },
        {
          company: "University of Arkansas",
          title: "Graduate Assistant",
          description:
            "I empoered 1800+ students through tutoring sessions. I deployed the course website used by students to access class sessions",
          img: "UA.png",
          url: "uark.edu",
        },
        {
          company: "DATAX",
          title: "Web Developer",
          description:
            "I designed and developed a production ready Vue JS and Firebase application. Live date is November of 2022. Phase two is development of customer API's",
          img: "datax-blanco.png",
          url: "portal.datax.com.bo",
        },
      ],
      skills: [
        {
          img: "vuejs.png",
        },
        {
          img: "python.png",
        },
        {
          img: "js.png",
        },

        {
          img: "postgres.png",
        },
      ],
      skillsToBe: [
        {
          img: "react.png",
        },
        {
          img: "mongo.png",
        },
        {
          img: "docker.png",
        },

        {
          img: "fastapi.png",
        },
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    this.checkScreen();
  },
  methods: {
    openEmailModal() {
      this.emailModal = !this.emailModal;
    },
    sendEmail(email) {
      console.log(email);
    },
    checkScreen() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 750) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;

  .title {
    display: flex;
    align-items: center;
    flex-direction: column;
    button {
      background-color: transparent;
    }
    h1 {
      text-align: center;
      font-size: 10rem;
    }
    h2 {
      color: white;
      text-align: center;
    }
    p {
      color: #7c5dfa;
      margin: auto;
      font-size: 1.5rem;
    }
    @media (max-width: 750px) {
      h1 {
        text-align: center;
        font-size: 4rem;
      }
      h2 {
        color: white;
        text-align: center;
      }
      p {
        color: #7c5dfa;
        margin: auto;
        font-size: 1.5rem;
      }
    }
  }
  .content {
    h1 {
      color: #7c5dfa;
      margin-bottom: 45px;
    }
    p {
      text-align: center;
      color: white;
      font-size: 1.5rem;
      margin: auto;
      @media (min-width: 750px) {
        width: 60%;
      }
    }
  }
  .cta-btn {
    display: flex;
    align-items: center;
    i {
      margin-right: 4px;
    }
  }
}
</style>
