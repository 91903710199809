<template>
  <div class="socials">
    <div v-for="(doc, index) in socials" :key="index" class="contain-image">
      <i
        :class="['pi ' + doc.icon, { white: this.type === 'page' }]"
        style="font-size: 2rem"
        @click="goTo(doc.url)"
      ></i>
    </div>
    <div class="contain-image">
      <i
        :class="{ white: this.type === 'page' }"
        class="pi pi-envelope"
        style="font-size: 2rem"
        @click="copy('ajordanbojanic@gmail.com')"
      ></i>
      <small v-show="active" class="tooltip">Copied to clipboard</small>
    </div>
    <div class="contain-image">
      <i
        :class="{ white: this.type === 'page' }"
        class="pi pi-phone"
        style="font-size: 2rem"
        @click="copy('+15713095111')"
      ></i>
    </div>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
export default {
  name: "SocialsComponent",
  props: ["type"],
  data() {
    return {
      active: null,
      socials: [
        {
          icon: "pi-linkedin",
          url: "https://www.linkedin.com/in/alejandrojordanbojanic/",
        },
        {
          icon: "pi-github",
          url: "https://github.com/ajordanb",
        },
      ],
    };
  },
  methods: {
    goTo(url) {
      window.location.href = url;
    },
    async copy(url) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(url);
        this.active = !this.active;
        setTimeout(() => {
          this.active = !this.active;
        }, 1000);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.socials {
  display: flex;
  align-content: center;
  justify-content: center;
  i {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:nth-child(1) {
      margin-right: 15px;
    }
    &:hover {
      transform: scale(1.1);
      color: #7c5dfa;
    }
  }
}
.contain-image {
  display: relative;
  padding: 5%;
  .tooltip {
    color: white;
    display: flex;
    position: absolute;
    background-color: #7c5dfa;
    padding: 5px;
    top: 75%;
    @media (max-width: 750px) {
      top: 90%;
    }
  }
}
.white {
  color: white;
}
</style>
