<template>
  <div>
    <div class="grid-4">
      <div v-for="(job, index) in jobs" :key="index" class="job-card">
        <img :src="require('@/assets/' + job.img)" alt="" />
        <div class="content">
          <p>
            <strong>{{ job.company }}</strong>
          </p>
          <small class="title"
            ><strong>{{ job.title }}</strong>
          </small>
          <small v-if="this.mobile === false">{{ job.description }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["jobs", "mobile"],
  components: {},
  name: "cardComponent",
};
</script>

<style lang="scss" scoped>
.grid-4 {
  margin-top: 45px;
  .job-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    background-color: rgba(124, 93, 250, 0.35);
    &:hover {
      background-color: rgba(124, 93, 250, 1);
      .content {
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
          0 2px 4px -1px rgba(0, 0, 0, 0.06);
      }
    }
    img {
      width: 50%;
      background-color: transparent;
      padding: 5%;
      margin: auto;
    }
    .content {
      background-color: white;
      display: flex;
      flex-direction: column;
      align-content: center;
      border-radius: 20px;
      padding-top: 15%;
      padding-bottom: 15%;
      padding-left: 5%;
      padding-right: 5%;
      height: 100%;
      .chart {
        height: 75%;
      }
      p {
        margin-bottom: 15px;
        background-color: transparent;
        color: #141625;
        font-size: 1.2rem;
        text-align: center;
      }
      small,
      small.title,
      strong {
        background-color: transparent;
        text-align: center;
      }
      small.title {
        margin-bottom: 10px;
      }
      @media (max-width: 750px) {
        border-radius: 6px;
      }
    }
    @media (max-width: 750px) {
      border-radius: 6px;
    }
  }
}
</style>
