<template>
  <router-view />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
section {
  background-color: #141625;
}
// globals
h1 {
  font-size: 4.75rem;
  color: white;
  text-align: center;
  @media (max-width: 750px) {
    font-size: 3.5rem;
  }
}
button,
.button {
  cursor: pointer;
  padding: 16px 24px;
  border-radius: 30px;
  border: none;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 8px;
  color: #fff;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    color: white;
    background-color: #7c5dfa;
  }
}

.dark-purple {
  background-color: #252945;
}

.red {
  background-color: #ec5757;
}

.purple {
  background-color: #7c5dfa;
}

.green {
  background-color: #33d69f;
}

.orange {
  background-color: #ff8f00;
}

// utility classes

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 5%;
  justify-content: center;
}

.nav-link {
  text-decoration: none;
  color: initial;
}

// Status Button Styling

.status-button {
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
  }
  font-size: 12px;
  margin-right: 30px;
  align-items: center;
  padding: 8px 30px;
  border-radius: 10px;
}

.paid {
  &::before {
    background-color: #33d69f;
  }
  color: #33d69f;
  background-color: rgba(51, 214, 160, 0.1);
}

.pending {
  &::before {
    background-color: #ff8f00;
  }
  color: #ff8f00;
  background-color: rgba(255, 145, 0, 0.1);
}

.draft {
  &::before {
    background-color: #dfe3fa;
  }
  color: #dfe3fa;
  background-color: rgba(223, 227, 250, 0.1);
}
// full page scroll
.scroller {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.scroller section {
  position: relative;
  width: 100%;
  height: 100%;
  scroll-snap-align: start;
}

header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background: transparent;
  z-index: 4;
}
// grids
.grid-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  @media (max-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  @media (max-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }
}
.emailbtn {
  transition: all 0.3s ease-in-out;

  background-color: #7c5dfa !important;
}
</style>
