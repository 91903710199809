<template>
  <div>
    <div class="grid-4">
      <div v-for="(doc, index) in data" :key="index" class="contain-image">
        <img :src="require('@/assets/' + doc.img)" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageGrid",
  props: ["data"],
};
</script>

<style lang="scss" scoped>
.grid-4 {
  align-items: center;
}
.contain-image {
  display: flex;
  padding: 5%;
  justify-content: center;
  img {
    max-width: 30%;
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>
